// "use client";
// TODO: Implement embed into Contentful & theme adaption
import { Locales } from "@/config/supported-countries";
import { ThemesG } from "@/types/design-system-types";
import { getRouteDetails, isAlcatraz } from "@/utils/helpers";
import { Entry } from "contentful";
import { Search } from "lucide-react";
import { useRouter } from "next/router";
import { HTMLAttributes, useEffect, useState } from "react";
import { ReactSearchAutocomplete } from "react-search-autocomplete";

type SearchType =
  | "indices"
  | "forex"
  | "commodities"
  | "shares"
  | "share baskets"
  | "treasuries"
  | "etfs"
  | "currencies"
  | null;

type InstrumentsSearchT = {
  placeholderText: string;
  searchType?: SearchType | null;
  options?: any;
  theme?: ThemesG;
} & HTMLAttributes<HTMLDivElement>;

type InstrumentData = {
  apiCode: string;
  name: string;
  url: string;
  class: SearchType;
};

const InstrumentsSearch = ({
  data,
  debug,
  className,
  options = data.fields.options,

  // Controlled by parent
  theme = data.fields.theme ?? "white-100",
  ...props
}: {
  data: Entry<InstrumentsSearchT>;
  debug?: any;
  className?: string;
} & InstrumentsSearchT) => {
  const { placeholderText, searchType, ...rest } = options;
  const [instrumentSearchData, setInstrumentSearchData] = useState([]);
  const router = useRouter();
  const { locale, region } = getRouteDetails(router);

  // Move this function somewhere better later
  const checkLocale = (locale: Locales) => {
    if (!locale) {
      return "en";
    }

    if (isAlcatraz(locale as Locales)) {
      return "en";
    }

    return locale.toLowerCase();
  };

  // On click of a search result, redirect to the instrument page
  const handleOnSelect = (item: InstrumentData) => {
    window.location.href = `https://www.cmcmarkets.com/${checkLocale(
      locale
    )}/instruments/${item.url}?search=1`;
  };

  const filterTo = searchType;

  const fetchData = async () => {
    try {
      if (instrumentSearchData.length) return;

      const response = await fetch(
        `https://ws.cmcmarkets.com/json/instruments_${
          isAlcatraz(locale) ? "en" : region
        }.json`
      );
      const jsonData = await response.json();
      let filteredData = [];

      // If search type specified, filter data
      if (searchType != null) {
        filteredData =
          filterTo !== null
            ? jsonData.filter((obj: InstrumentData) => obj.class === filterTo)
            : jsonData;
      }
      // If no search type specified, return all data
      else {
        filteredData = jsonData;
      }

      // Map data to match react-search-autocomplete props
      filteredData = filteredData.map(({ apiCode, name, url }: InstrumentData) => ({
        id: apiCode,
        name,
        url,
      }));
      setInstrumentSearchData(filteredData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div
      {...rest}
      className={`instrument-search relative w-full xs:min-w-[360px] max-w-[400px] z-30 ${
        className ?? ""
      }`}
    >
      {debug}
      <ReactSearchAutocomplete
        onSelect={handleOnSelect}
        onHover={fetchData}
        onFocus={fetchData}
        className="z-20"
        items={instrumentSearchData}
        maxResults={5}
        inputDebounce={100}
        placeholder={placeholderText}
      />
      <Search className="search-icon absolute left-4 rtl:right-4 top-0 z-20 h-full w-4" />
    </div>
  );
};

export default InstrumentsSearch;
